import LoginComponent from 'components/Auth/Login_Component'
import { Card, Col, Container } from 'react-bootstrap'

document.title = process.env.REACT_APP_TITLE
function Login () {
  return (
    <Container fluid>
      <div className='row'>
        <Col className='ms-auto me-auto mt-5' md='4'>
          <Card>
            <Card.Header>
              <Card.Title as='h4'>Login</Card.Title>
            </Card.Header>
            <Card.Body>
              <LoginComponent />
            </Card.Body>
            <Card.Footer />
          </Card>
        </Col>
      </div>
    </Container>
  )
}

export default Login
