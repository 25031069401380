import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as parse from '../../helpers/Parse'
import * as yup from 'yup'
import { Button } from 'react-bootstrap'

import { useNavigate } from 'react-router-dom'
import InputFormikBootstrap from '../Forms/InputFormikBootstrap'

const schema = yup.object().shape({
  username: yup.string().required('Este campo es obligatorio'),
  password: yup.string()
    .min(6, 'Minimo 6 caracteres!')
    .max(50, 'Demasiado largo!')
    .required('Este campo es obligatorio')
})

function LoginComponent () {
  const navigate = useNavigate()

  useEffect(() => {
    parse.getCurrentUser().then(response => {
      if (response !== null) {
        navigate('/')
      }
    })
  }, [])

  return (
    <Formik
      initialValues={{
        username: '',
        password: ''
      }}
      validationSchema={schema}
      onSubmit={async (values, { resetForm }) => {
        const data = {
          data: values
        }
        const login = await parse.Login(data)
        if (login.get('username')) {
          navigate('/')
        }
      }}
    >
      {(values) => (
        <Form>

          <InputFormikBootstrap label='Nombre de Usuario' name='username' type='text' placeholder='Username' />
          <InputFormikBootstrap label='Password' name='password' type='password' placeholder='Password' />
          <Button variant='primary' type='submit'>
            Entrar
          </Button>
        </Form>
      )}
    </Formik>
  )
}
export default LoginComponent
