import useRemesas from 'components/Hooks/useRemesas'
import { Card, Col, Container, Table } from 'react-bootstrap'
import { Toaster } from 'react-hot-toast'
import RemesaPendiente from './RemesaPendiente'

function RemesasPendientes () {
  const { pendientes } = useRemesas()

  return (
    <Container fluid>
      <Toaster />
      <div className='row'>
        <Col md='12'>
          <Card className='strpied-tabled-with-hover'>
            <Card.Header>
              <Card.Title as='h4'> Remesas Pendientes </Card.Title>
              <p className='card-category'>Remesas pagadas en proceso</p>
            </Card.Header>
            <Card.Body className='table-full-width table-responsive px-0'>
              <Table className='table-hover '>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Nombre</th>
                    <th>Tipo de Remesa</th>
                    <th>Cantidad</th>
                    <th>Envio</th>
                    <th>Telefono</th>
                    <th>Pagada</th>
                    <th>Completada</th>
                  </tr>
                </thead>
                <tbody>
                  {pendientes.length > 0
                    ? (
                        pendientes.map((pendiente) => (
                          <RemesaPendiente pendiente={pendiente} key={pendiente.objectId} />
                        ))
                      )
                    : (
                      <tr>
                        <td colSpan={6}>Estamos al Día</td>
                      </tr>
                      )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </Container>
  )
}

export default RemesasPendientes
