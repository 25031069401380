/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { faBook, faClock, faGauge, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import Dashboard from 'views/Dashboard'
import LocalServices from 'views/LocalServices'
import Pendientes from 'views/Pendientes'
import RemesasHistory from 'views/RemesasHistory'
// import Settings from 'views/Settings'

const dashboardRoutes = [
  // Rutas Primarias
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: faGauge,
    component: Dashboard,
    layout: '/'
  },
  {
    path: '/pendientes',
    name: 'Pendientes',
    icon: faClock,
    component: Pendientes,
    layout: '/'
  },
  {
    path: '/local-services',
    name: 'Servicios Locales',
    icon: faMoneyBill,
    component: LocalServices,
    layout: '/'
  },
  {
    path: '/remesas',
    name: 'Historial de Remesas',
    icon: faBook,
    component: RemesasHistory,
    layout: '/'
  }
  // {
  //   path: '/promociones',
  //   name: 'SMS Promocionales',
  //   icon: faSms,
  //   component: Promociones,
  //   layout: '/'
  // },
  // {
  //   path: '/settings',
  //   name: 'Ajustes',
  //   icon: faGear,
  //   component: Settings,
  //   layout: '/'
  // }
]

export default dashboardRoutes
