import axios from 'axios'
import RemesasContext from 'context/RemesasContext'
import { useContext } from 'react'

function useRemesas () {
  const { pendientes, completadas, setRefresh, refresh, loading, currentPage, setCurrentPage, totalPages, setCompletadas, getRemesasCompletadas, setTotalRemesas, limit } = useContext(RemesasContext)

  const updateRemesas = () => {
    setRefresh(!refresh)
  }

  const RemesasMesAnterior = completadas.filter((remesa) => new Date(remesa.updatedAt).getMonth() === new Date().getMonth() - 1 && new Date(remesa.updatedAt).getFullYear() === new Date().getFullYear())

  const getDataFromClient = (phone) => completadas.filter(remesa => remesa.confirmation_phone === phone)
  // const TotalCobrado = mensuales.reduce((total, remesa) => total + remesa.pagado, 0)
  // const TotalPagado = mensuales.reduce((total, remesa) => remesa.tipoRemesa !== 'cup' ? total + remesa.cantidad : total + (remesa.cantidad / 150), 0)

  const convertTZ = (date) => {
    return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: 'America/Havana' }))
  }

  const markAsCompleted = (id, contactId) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/remesas/completed`, { id, contact_id: contactId })
      .then(response => {
        console.log(response.data)
        if (response.data.message === 'succeeded') {
          return true
        } else {
          return false
        }
      })
      .catch(error => {
        console.error(error)
        return false
      })
  }

  // const remesasWeek = (fechaInicial, fechaFinal) => {
  //   const zonaHorariaNavegador = Intl.DateTimeFormat().resolvedOptions().timeZone
  //   const remesasConvertidas = completadas.map((remesa) => {
  //     const fechaRemesa = new Date(remesa.updatedAt)
  //     const fechaRemesaConvertida = fechaRemesa.toLocaleString('en-US', { timeZone: zonaHorariaNavegador })
  //     return { ...remesa, updatedAt: new Date(fechaRemesaConvertida) }
  //   })
  //   const remesasFiltradas = remesasConvertidas.filter((remesa) => {
  //     const fechaRemesa = new Date(remesa.updatedAt)
  //     return fechaRemesa >= fechaInicial && fechaRemesa <= fechaFinal
  //   })
  //   return remesasFiltradas
  // }

  const CountMLCVaue = 0
  // mensuales.filter(remesa => remesa.tipoRemesa === 'mlc').reduce((total, remesa) => total + remesa.cantidad, 0)

  return (
    { pendientes, completadas, updateRemesas, RemesasMesAnterior, getDataFromClient, markAsCompleted, CountMLCVaue, loading, convertTZ, currentPage, setCurrentPage, totalPages, setCompletadas, setTotalRemesas, getRemesasCompletadas, limit }
  )
}

export default useRemesas
