import RemesasLocales from 'components/Dashboard/RemesasLocales'
import { useState } from 'react'
import { Col, Container, Tab, Tabs } from 'react-bootstrap'

const LocalServices = () => {
  const [key, setKey] = useState('remesas')
  return (
    <Container>
      <div className='row'>
        <Col sm='12' md='6' lg='6'>
          <Tabs
            id='controlled-tab-example'
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className='d-flex align-items-center'
          >
            <Tab eventKey='remesas' title='Remesas'>
              <RemesasLocales disableForm={false} />
            </Tab>
            <Tab eventKey='facturas' title='Facturas' disabled>
              {/* <FacturasLocales disableForm={false} /> */}
            </Tab>

          </Tabs>
        </Col>
      </div>
    </Container>
  )
}

export default LocalServices
