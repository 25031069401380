import useContacts from 'components/Hooks/useContacts'
import useRemesas from 'components/Hooks/useRemesas'
import { Button } from 'react-bootstrap'
import { toast } from 'react-hot-toast'

function RemesaPendiente ({ pendiente }) {
  const { getContactId } = useContacts()
  const { markAsCompleted, updateRemesas } = useRemesas()

  const RemesaCompletada = async (id, confirmationPhone) => {
    const contactId = getContactId(confirmationPhone)
    if (contactId) {
      markAsCompleted(id, contactId)
        .then((response) => {
          if (response === true) {
            toast.success('Remesa marcada como lista y Notificación enviada')
            setTimeout(() => {
              updateRemesas()
            }, 2000)
          } else {
            toast.error('Error al marcar la remesa como lista')
          }
        })
        .catch((error) => {
          console.error(error)
          toast.error('Error al marcar la remesa como lista')
        })
    } else {
      toast.error('No se encontró el contacto. Revise que el numero de telefono este registrado en la lista de contactos')
    }
  }
  return (
    <tr>
      <td>
        {new Date(pendiente.createdAt).toLocaleString()}
      </td>
      <td>{pendiente.nombre}</td>
      <td>{(pendiente.tipoRemesa).toUpperCase()}</td>
      <td>{pendiente.cantidad}</td>
      <td>{pendiente.envio}</td>
      <td>{pendiente.telefono}</td>
      <td>{pendiente.isPaid === undefined ? 'Pagada' : (pendiente.isPaid ? 'Pagada' : 'Pendiente')}</td>
      <td>
        <Button
          variant='primary'
          className='btn-fill'
          onClick={() => {
            RemesaCompletada(pendiente.objectId, pendiente.confirmation_phone)
          }}
        >
          Completada
        </Button>
      </td>
    </tr>
  )
}

export default RemesaPendiente
