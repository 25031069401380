import { InputFormikField } from 'components/Forms/InputFormikField'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import toast, { Toaster } from 'react-hot-toast'

// crea un esquema de validación para el formulario usando Yup
import useContacts from 'components/Hooks/useContacts'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('El nombre es requerido')
    .min(3, 'El nombre debe tener al menos 3 caracteres')
    .max(50, 'El nombre debe tener menos de 50 caracteres'),
  number: Yup.string()
    .required('El número es requerido')
    .min(3, 'El número debe tener al menos 3 caracteres')
    .max(50, 'El número debe tener menos de 50 caracteres')
})

function AddContact ({ openModal, setOpenModal }) {
  const [buttonStatus, setButtonStatus] = React.useState(false)

  const { handleAddContact, groups } = useContacts()
  const handleClose = () => setOpenModal(false)
  return (
    <>
      <Toaster />
      <Modal show={openModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Contacto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              name: '',
              number: '',
              grupos: []
            }}
            onSubmit={(values, { resetForm }) => {
              setButtonStatus(true)
              console.log(values)
              const gruposId = groups.map(g => values.grupos.includes(g.name) ? g.id : null).filter(g => g !== null)
              const data = {
                name: values.name,
                number: values.number,
                groups: gruposId
              }
              const results = handleAddContact(data)
              if (results) {
                toast.success('Contacto agregado con éxito')
                resetForm()
                setOpenModal(false)
              } else {
                toast.error('Error al agregar el contacto')
              }
            }}
          >
            {() => (
              <Form>
                <InputFormikField
                  label='Nombre'
                  name='name'
                  type='text'
                  placeholder='Nombre del Contacto'
                />
                <InputFormikField
                  label='Número'
                  name='number'
                  type='text'
                  placeholder='Numero de Telefono'
                />
                {/* Genera el grupo de checkbox usando las opciones: Remesas, Paqueteria y Promociones */}
                <h4>Grupos</h4>
                <div className='d-flex flex-row'>
                  <label className='d-flex flex-row justify m-2'>
                    <Field className='form-check' type='checkbox' name='grupos' value='Remesas' /> &nbsp; <span>Remesas</span>
                  </label>
                  <label className='d-flex flex-row justify m-2'>
                    <Field className='form-check' type='checkbox' name='grupos' value='Paqueteria' /> &nbsp; <span>Paqueteria</span>
                  </label>
                  <label className='d-flex flex-row justify m-2'>
                    <Field className='form-check' type='checkbox' name='grupos' value='Promociones' /> &nbsp; <span>Promociones</span>
                  </label>
                </div>
                <Button variant='primary' type='submit' disabled={buttonStatus}>
                  Agregar
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  )
}

export default AddContact
