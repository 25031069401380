import { Button, Form } from 'react-bootstrap'
import useRemesas from 'components/Hooks/useRemesas'
import React, { useState } from 'react'
import axios from 'axios'

function RemesasSearch () {
  const { setCompletadas, setTotalRemesas, setTotalPages, limit } = useRemesas()
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSearch = (e) => {
    e.preventDefault()
    setLoading(true)
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/v2/remesas/?client=${search}&page=1&limit=${limit}&status=false`)
      .then(({ data }) => {
      // Manejar la respuesta
        setCompletadas(data.data.results) // Actualiza las remesas paginadas
        setTotalRemesas(data.totalCount) // Actualiza el total de remesas
        setTotalPages(Math.ceil(data.data.totalCount / limit)) // Calcula el total de páginas
      })
      .catch(error => {
      // Manejar el error
        console.error('Error al obtener remesas por cliente: ', error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Form onSubmit={handleSearch} className='d-flex gap-3'>
      <input
        type='text' placeholder='Buscar por nombre' className='form-control' onChange={(e) => setSearch(e.target.value)}
      />
      <Button type='submit' size='sm' disabled={loading} style={{ color: '#000', fontWeight: 'bold' }}>{loading ? 'Buscando...' : 'Buscar'}</Button>
    </Form>
  )
}

export default RemesasSearch
