/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import AdminNavbar from 'components/Navbars/AdminNavbar'
import Footer from 'components/Footer/Footer'
import Sidebar from 'components/Sidebar/Sidebar'
import routes from 'routes.js'
import Contacts from 'views/Contacts'
import { useAuth } from 'context/AuthContext'
import EditContact from 'views/EditContact'

// import sidebarImage from "assets/img/sidebar-3.jpg";

function Admin () {
  const mainPanel = React.useRef(null)
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  useEffect(() => {
    if (!currentUser) {
      navigate('/login')
    }
  }, [])

  return (

    <div className='wrapper'>
      <Sidebar routes={routes} />
      <div className='main-panel' ref={mainPanel}>
        <AdminNavbar currentUser={currentUser} />
        <div className='content'>
          <Routes>
            {
                routes.map(
                  (r, key) => (
                    <Route exact key={key} path={r.layout + r.path} element={<r.component />} />
                  )
                )
              }
            <Route exact path='/contacts' element={<Contacts />} />
            <Route exact path='/contact/:id' element={<EditContact />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Admin
