import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Col } from 'react-bootstrap'

function RemesasDiarias ({ diarias }) {
  return (
    <Card className='card-stats'>
      <Card.Body>
        <div className='row'>
          <Col xs='5'>
            <div className='icon-big text-center icon-warning'>
              <FontAwesomeIcon icon={faCalendarDay} style={{ color: '#80391e' }} />
              {/* #80391e */}
            </div>
          </Col>
          <Col xs='7'>
            <div className='numbers'>
              <p className='card-category'>Remesas del Dia</p>
              <Card.Title as='h4'>{diarias || '⌛'}</Card.Title>
            </div>
          </Col>
        </div>
      </Card.Body>
      {/* <Card.Footer>
      <hr></hr>
      <div className="stats">
        <i className="fas fa-redo mr-1"></i>
        Update Now
      </div>
    </Card.Footer> */}
    </Card>
  )
}

export default RemesasDiarias
