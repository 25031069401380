import Parse from 'parse/dist/parse.min.js'

const PARSE_APPLICATION_ID = process.env.REACT_APP_B4A_APP_ID
const PARSE_HOST_URL = process.env.REACT_APP_B4A_URL
const PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_B4A_JS_KEY

Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY)
Parse.serverURL = PARSE_HOST_URL

export const Login = async (data) => {
  const { username, password } = data.data

  try {
    const loggedInUser = await Parse.User.logIn(username, password)
    const currentUser = await Parse.User.current()

    if (currentUser !== loggedInUser) {
      throw new Error('Unmatched users')
    }

    return loggedInUser
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getCurrentUser = async function () {
  const currentUser = await Parse.User.current()
  if (!currentUser) {
    throw new Error('No user is currently logged in')
  }
  return currentUser
}

export const doUserLogOut = async () => {
  await Parse.User.logOut()
  const currentUser = await Parse.User.current()
  if (currentUser !== null) {
    throw new Error('User log out failed')
  }
  return true
}

export const UpdateUser = async (data) => {
  const { password, email, name, newPassword, username, token } = data

  const user = await Parse.User.become(token)
  user.set('username', username !== '' ? username : user.get('username'))
  user.set('password', newPassword !== '' ? newPassword : password)
  user.set('email', email !== '' ? email : user.get('email'))
  user.set('name', name !== '' ? name : user.get('name'))

  try {
    const result = await user.save()
    return result
  } catch (error) {
    console.error('User cannot be saved using token: ', token, ' Error: ', error)
    throw error
  }
}

export default Parse
