export default (tipoRemesa, cantidad, tasa) => {
  if (tipoRemesa === 'cup') {
    return Math.round(Number(cantidad) / Number(tasa.CUP))
  }
  if (tipoRemesa === 'mlc') {
    return Math.round(Number(cantidad) * Number(tasa.MLC))
  }
  if (tipoRemesa === 'usd') {
    return Math.round(Number(cantidad) * Number(tasa.USD))
  }
}
