import { Card, Col } from 'react-bootstrap'

function RemesasCount ({ count }) {
  return (
    <Card className='card-stats'>
      <Card.Body>
        <div className='row'>
          <Col xs='5'>
            <div className='icon-big text-center icon-warning'>
              <i className='nc-icon nc-notes text-warning' />
            </div>
          </Col>
          <Col xs='7'>
            <div className='numbers'>
              <p className='card-category'>Remesas Completadas</p>
              <Card.Title as='h4'>{count || '⌛'}</Card.Title>
            </div>
          </Col>
        </div>
      </Card.Body>
      {/* <Card.Footer>
        <hr></hr>
        <div className="stats">
          <i className="fas fa-redo mr-1"></i>
          Update Now
        </div>
      </Card.Footer> */}
    </Card>
  )
}

export default RemesasCount
