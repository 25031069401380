import axios from 'axios'
import ContactosContext from 'context/ContactosContext'
import { useContext } from 'react'

const useContacts = () => {
  const { contactos, groups, getContactos } = useContext(ContactosContext)

  const handleDeleteContact = async (id) => {
    const response = await fetch(`https://api.sendhub.com/v1/contacts/${id}/?username=${process.env.REACT_APP_SENDHUB_USERNAME}&api_key=${process.env.REACT_APP_SENDHUB_APIKEY}`, {
      method: 'DELETE'
    })
    if (response.status === 204) {
      // getContactos()
    }
  }

  const handleAddContact = async (contact) => {
    const response = await fetch(`https://api.sendhub.com/v1/contacts/?username=${process.env.REACT_APP_SENDHUB_USERNAME}&api_key=${process.env.REACT_APP_SENDHUB_APIKEY}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(contact)
    })
    if (response.status === 201) {
      // getContactos()
      return true
    } else {
      return false
    }
  }

  const SendSMSToGroup = async (groupid, body) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/bulksms`, { grupo: groupid, body })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  const getContactId = (phone) => contactos.filter(contact => contact.number === `+1${phone}`)[0]?.id

  return { contactos, groups, getContactos, handleDeleteContact, handleAddContact, getContactId, SendSMSToGroup }
}

export default useContacts
