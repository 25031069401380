/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { faContactCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Container, Nav, Navbar } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import routes from 'routes.js'
import * as parse from '../../helpers/Parse'

function Header ({ currentUser }) {
  const navigate = useNavigate()
  const location = useLocation()

  const mobileSidebarToggle = (e) => {
    e.preventDefault()
    document.documentElement.classList.toggle('nav-open')
    const node = document.createElement('div')
    node.id = 'bodyClick'
    node.onclick = function () {
      this.parentElement.removeChild(this)
      document.documentElement.classList.toggle('nav-open')
    }
    document.body.appendChild(node)
  }

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].path) !== -1) {
        return routes[i].name
      }
    }
    return ''
  }

  const logout = () => {
    parse.doUserLogOut()
      .then(response => {
        if (response === true) {
          navigate('/login')
        }
      })
  }

  return (
    <>
      {
        currentUser === undefined
          ? null
          : (
            <Navbar bg='light' expand='lg'>
              <Container>
                <div className='d-flex justify-content-center align-items-center ml-2 ml-lg-0'>
                  <Button
                    variant='dark'
                    className='d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle px-2'
                    onClick={mobileSidebarToggle}
                  >
                    <i className='fas fa-ellipsis' />
                  </Button>
                  <Navbar.Brand
                    href='#/'
                    onClick={(e) => e.preventDefault()}
                    className='mr-2'
                  >
                    {getBrandText()}
                  </Navbar.Brand>
                </div>
                <Navbar.Toggle aria-controls='basic-navbar-nav' className='mr-5'>
                  <span className='navbar-toggler-bar burger-lines' />
                  <span className='navbar-toggler-bar burger-lines' />
                  <span className='navbar-toggler-bar burger-lines' />
                </Navbar.Toggle>
                <Navbar.Collapse id='basic-navbar-nav'>

                  <Nav className='ms-auto ' navbar>
                    <Nav.Item>
                      <Link
                        className='m-0 border-0 p-0 pt-2  mx-3 btn btn-dark nav-link align-self-center'
                        to='/contacts'
                      >
                        <FontAwesomeIcon icon={faContactCard} />
                      </Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Button as={Nav.Link} variant='dark' className='m-0 border-0 p-0 mx-3' onClick={logout}>
                        <span className='no-icon'>Salir</span>
                      </Button>
                    </Nav.Item>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            )
      }
    </>
  )
}

export default Header
