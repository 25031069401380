/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Logotipo from 'views/Logotipo'
import SidebarNav from './SidebarNav'

function Sidebar ({ image, routes }) {
  return (
    <div className='sidebar' data-color='black'>
      <div
        className='sidebar-background'
        style={{
          backgroundImage: 'url(' + image + ')'
        }}
      />
      <div className='sidebar-wrapper'>
        <div className='logo d-flex align-items-center justify-content-start'>
          <a
            href='/'
            className='simple-text logo-mini mx-1'
          />
          <a className='simple-text' href='/'>
            {/* ACF Francos Services Dashboard */}
            <div className='row d-flex m-auto'>
              <Logotipo />
            </div>
          </a>
        </div>
        <SidebarNav routes={routes} />
      </div>
    </div>
  )
}

export default Sidebar
