import { faMoneyCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useRemesas from 'components/Hooks/useRemesas'
import { Card, Col } from 'react-bootstrap'

function RemesasEnMLC () {
  const { CountMLCVaue } = useRemesas()

  return (

    <Card className='card-stats'>
      <Card.Body>
        <div className='row'>
          <Col xs='5'>
            <div className='icon-big text-center icon-warning'>
              <FontAwesomeIcon icon={faMoneyCheck} style={{ color: '#2596be' }} />
            </div>
          </Col>
          <Col xs='7'>
            <div className='numbers'>
              <p className='card-category'>Valor total en MLC</p>
              <Card.Title as='h4'>{CountMLCVaue}</Card.Title>
            </div>
          </Col>
        </div>
      </Card.Body>
    </Card>
  )
}

export default RemesasEnMLC
