import React from 'react'
import { Card, Col } from 'react-bootstrap'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
ChartJS.register(ArcElement, Tooltip, Legend)

function RemesasTipo ({ mensuales }) {
  // const { mensuales } = stats

  const data = {
    labels: ['MLC', 'USD'],
    datasets: [
      {
        data: [mensuales.filter(remesa => remesa.tipoRemesa === 'mlc').length, mensuales.filter(remesa => remesa.tipoRemesa === 'usd').length],
        backgroundColor: ['blue', 'red'],
        borderColor: ['gray'],
        borderWidth: 1
      }
    ]
  }

  return (
    <Col md='5' className='m-auto'>
      <Card>
        <Card.Header>
          <Card.Title as='h4'>Remesas por tipo en el mes</Card.Title>
          <p className='card-category'>Remesas por Tipo</p>
        </Card.Header>
        <Card.Body>
          <Doughnut data={data} />
        </Card.Body>
      </Card>
    </Col>
  )
}

export default RemesasTipo
