import { Field, useField } from 'formik'

import { Form as FormBootstrap } from 'react-bootstrap'

function InputFormikBootstrap ({ id, label, children, ...props }) {
  const [field, meta] = useField(props)
  return (

    <FormBootstrap.Group className='mb-2' controlId={id}>
      <FormBootstrap.Label>{label}</FormBootstrap.Label>
      <Field
        className={
                        `${
                            !meta.touched
? null
                            : meta.error && meta.touched ? 'is-invalid' : 'is-valid'
                        }`
                    }
        {...field}
        {...props}
        as={FormBootstrap.Control}
      >{children}
      </Field>
      {meta.error && meta.touched
        ? (
          <div>
            <p className='text-danger'>{meta.error}</p>
          </div>
          )
        : null}
    </FormBootstrap.Group>
  )
}

export default InputFormikBootstrap
