/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createRoot } from 'react-dom/client'

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ContactosProvider } from 'context/ContactosContext'
import { RemesasProvider } from 'context/RemesasContext'
import AdminLayout from 'layouts/Admin'
import Login from 'views/Login'
import './assets/css/animate.min.css'
import './assets/css/demo.css'
import './assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0'
import { AuthProvider } from 'context/AuthContext'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

root.render(
  <AuthProvider>
    <RemesasProvider>
      <ContactosProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/' exact element={<Navigate to='/pendientes' />} />
            <Route path='/*' element={<AdminLayout />} />
          </Routes>
        </BrowserRouter>
      </ContactosProvider>
    </RemesasProvider>
  </AuthProvider>
)
