/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

class Footer extends Component {
  render () {
    return (
      <footer className='footer px-0 px-lg-3'>
        <Container fluid>
          <nav>
            <ul className='footer-menu'>
              <li>
                <a href='/' onClick={(e) => e.preventDefault()}>
                  Home
                </a>
              </li>
              <li>
                <a href='https://www.acffrancos.com' target='_blank' rel='noreferrer'>
                  Company
                </a>
              </li>

            </ul>
            <p className='copyright text-center'>
              © {new Date().getFullYear()}{' '}
              <a href='https://www.acffrancos.com'>ACF Francos</a>, La mejor agencia de remesas del mundo
            </p>
          </nav>
        </Container>
      </footer>
    )
  }
}

export default Footer
