import React, { createContext, useContext, useState, useEffect } from 'react'
import { getCurrentUser } from '../helpers/Parse' // Asegúrate de importar tu función getCurrentUser

// Crear el Context
const AuthContext = createContext()

// Crear el Provider que permitirá a otros componentes suscribirse a los cambios de este Context
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)

  // Usa el hook useEffect para obtener el usuario actual al montar el componente
  useEffect(() => {
    const fetchUser = async () => {
      const user = await getCurrentUser()
      setCurrentUser(user)
    }

    fetchUser()
  }, [])

  const value = {
    currentUser,
    setCurrentUser
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

// Crear un Hook personalizado que permitirá a los componentes acceder fácilmente al Context
export const useAuth = () => {
  return useContext(AuthContext)
}
