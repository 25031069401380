import axios from 'axios'

export const SubmitRemesa = async (args) => {
  let data = null

  if (args?.resend === true) {
    data = args
  } else {
    const { tipoRemesa, values, confirmationPhone } = args
    data = { tipoRemesa, confirmation_phone: confirmationPhone, ...values }
  }
  console.log(data)
  const request = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/remesas/local`,
    data
  )
  if (request.status === 200) {
    return true
  } else {
    console.log(request.response.data)
    return false
  }
}
