import * as yup from 'yup'
export default () => {
  return yup.object().shape({
    // confirmation_phone: yup
    //   .string()
    //   .matches(/^[0-9]+$/, 'El telefono solo lleva digitos')
    //   .min(10, 'Los telefonos en USA tienen 10 digitos')
    //   .max(10, 'Los telefonos en USA tienen 10 digitos')
    //   .required('Este campo es obligatorio'),
    telefono: yup
      .string()
      .required('Este campo es obligatorio')
      .matches(/^[0-9]+$/, 'El telefono solo lleva digitos')
      .min(8, 'Los telefonos en Cuba tienen 8 digitos')
      .max(8, 'Los telefonos en Cuba tienen 8 digitos'),
    cantidad: yup
      .string()
      .required('Este campo es obligado')
      .matches(/^[0-9]+$/gi, 'Must be a Number')
      .required('Required'),
    nombre: yup.string().required('Este campo es obligado')
  })
}
