import axios from 'axios'
import ThreeDots from 'components/Loaders/ThreeDots'
import React, { useEffect, useState } from 'react'

const RemesasContext = React.createContext({})

export const RemesasProvider = ({ children }) => {
  const [pendientes, setPendientes] = useState([])
  const [completadas, setCompletadas] = useState([])
  const [totalRemesas, setTotalRemesas] = useState(0)
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const limit = 100 // Ajusta este valor según tus necesidades

  const getRemesasPendientes = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v2/remesas?page=1&limit=100&status=false`)
      .then(({ data }) => {
        console.log(data)
        setPendientes(data.data.results)
      })
      .catch(e => console.log(e))
  }

  const getRemesasCompletadas = async () => {
    console.log('Recargando remesas de la pagina: ', currentPage)
    try {
      const URL = `${process.env.REACT_APP_BASE_URL}/api/v2/remesas?page=${currentPage}&limit=${limit}`
      console.log('URL: ', URL)
      const response = await axios.get(URL)
      setCompletadas(response.data.data.results) // Actualiza las remesas paginadas
      setTotalRemesas(response.data.totalCount) // Actualiza el total de remesas
      setTotalPages(Math.ceil(response.data.data.totalCount / limit)) // Calcula el total de páginas
    } catch (error) {
      console.error('Error al obtener remesas: ', error)
    }
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([getRemesasPendientes(), getRemesasCompletadas()])
      .then(() => setLoading(false))
      .catch(error => console.log(error))
  }, [refresh, currentPage]) // Agrega currentPage a las dependencias

  const exportValues = {
    pendientes,
    completadas,
    setRefresh,
    refresh,
    loading,
    currentPage,
    setCurrentPage,
    totalPages,
    totalRemesas,
    setCompletadas,
    getRemesasCompletadas,
    limit,
    setTotalRemesas,
    setTotalPages
  }

  return (
    <RemesasContext.Provider value={exportValues}>
      {(pendientes !== undefined) ? children : <ThreeDots />}
    </RemesasContext.Provider>
  )
}

export default RemesasContext
