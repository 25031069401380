import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddContact from 'components/Contacts/AddContact'
import useContacts from 'components/Hooks/useContacts'
import { useState } from 'react'
import { Button, Col, Container, Table } from 'react-bootstrap'
import { Toaster } from 'react-hot-toast'
import { Link } from 'react-router-dom'

function Contacts () {
  const { contactos, groups, handleDeleteContact } = useContacts()
  const [showContacts, setShowContacts] = useState(contactos)

  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = () => setOpenModal(true)

  // Generate a function to search contact by name

  const handleSearch = (e) => {
    const { value } = e.target
    const lowercasedValue = value.toLowerCase()
    const filteredContacts = contactos.filter((contact) => {
      return contact.number.toLowerCase().includes(lowercasedValue) || contact.name.toLowerCase().includes(lowercasedValue)
    })
    setShowContacts(filteredContacts)
  }

  return (
    <Container fluid>
      <Toaster />
      <AddContact openModal={openModal} setOpenModal={setOpenModal} />
      <div className='row'>
        <Col lg='3' sm='6'>
          <h2>Contacts</h2>
        </Col>
      </div>

      <div className='row'>
        <Col lg='10' sm='7'>
          <Button variant='primary' className='mb-3' onClick={handleOpenModal}>Add Contact</Button>

          <input type='text' className='form-control' placeholder='305 1234 5678' onChange={handleSearch} />
          <Table className='strpied-tabled-with-hover table-hover table-full-width table-responsive px-0 mx-0'>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Telefono</th>
                {/* <th>Grupos</th> */}
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                (contactos && groups) && showContacts.map((contacto) => (
                  <tr key={contacto.id}>
                    <td>{contacto.name}</td>
                    <td>{contacto.number}</td>
                    <td>
                      {/* &nbsp; */}
                      <Button variant='danger' onClick={() => handleDeleteContact(contacto.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                      {/* Genera un elemento Link y aplica los estilos para que parezca un boton de bootstrap */}
                      &nbsp;
                      <Link to={`/contact/${contacto.id}`} className='btn btn-primary'>
                        <FontAwesomeIcon icon={faEdit} />
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Col>
      </div>
    </Container>
  )
}

// const styles = {
//   toast: {
//     color: '#00F',
//     backgroundColor: '#',
//     padding: '10px',
//     borderRadius: '10px',
//     // fontSize: '1.5rem',
//     // genera una sombra
//     boxShadow: '0 0 10px #000',
//     zIndex: 9999
//   }
// }

export default Contacts
