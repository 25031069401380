import { Card, Col } from 'react-bootstrap'

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

function RemesasLineChart ({ mensuales }) {
  const days = []
  mensuales.forEach(remesa => {
    const day = new Date(remesa.createdAt.toString()).getDate().toString()
    if (!days.includes(day)) {
      days.unshift(day)
    }
  })

  const label = Array.from(new Set(days))

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: false
      }
    }
  }

  const remesasDayMLC = label.map(day => mensuales.filter(remesa => (new Date(remesa.createdAt.toString()).getDate()).toString() === day).filter(remesa => remesa.tipoRemesa === 'mlc'))
  const ds1 = remesasDayMLC.map(remesa => remesa.reduce((totales, r) => totales + r.cantidad, 0))

  const remesasDayUSD = label.map(day => mensuales.filter(remesa => (new Date(remesa.createdAt.toString()).getDate()).toString() === day).filter(remesa => remesa.tipoRemesa === 'usd'))
  const ds2 = remesasDayUSD.map(remesa => remesa.reduce((totales, r) => totales + r.cantidad, 0))

  const data = {
    labels: label,
    datasets: [
      {
        label: 'MLC',
        data: ds1,
        borderColor: 'blue',
        backgroundColor: 'blue'
      },
      {
        label: 'USD',
        data: ds2,
        borderColor: 'red',
        backgroundColor: 'red'
      }
    ]
  }

  return (
    <Col md='7'>
      <Card>
        <Card.Header>
          <Card.Title as='h4'>Comparativa por Dias</Card.Title>
          <p className='card-category'>Movimiento en el mes</p>
        </Card.Header>
        <Card.Body>
          <Line options={options} data={data} />
        </Card.Body>
        {/* <Card.Footer></Card.Footer> */}
      </Card>
    </Col>
  )
}

export default RemesasLineChart
