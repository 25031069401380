import { Field, useField } from 'formik'

export const InputFormikField = ({ id, label, children, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className='form-group mt-4 mb-4'>
      <label htmlFor={id} className='font-bold mb-3'>{label}</label>

      <Field
        className={
                    `form-control  ${!meta.touched ? null : meta.error && meta.touched ? 'is-invalid' : 'is-valid'} `
                }
        {...field}
        {...props}
      >

        {children}
      </Field>

      {meta.error && meta.touched

        ? <p className='text-red-600 font-bold text-sm'>{meta.error}</p>
        : null}
    </div>
  )
}
