import RemesasPendientes from 'components/Dashboard/RemesasPendientes'
import { Container } from 'react-bootstrap'

function Pendientes () {
  return (
    <Container fluid>
      <div className='row'>
        <RemesasPendientes />
      </div>
    </Container>
  )
}

export default Pendientes
