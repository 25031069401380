import axios from 'axios'
import Parse from '../../helpers/Parse'
const { useState, useEffect } = require('react')

const useTasa = () => {
  const [tasa, setTasa] = useState({})

  useEffect(() => {
    getTasa()
  }, [])

  const getTasa = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/tasa/gettasa`)
      .then(response => setTasa(response.data))
      .catch(error => console.log(error))
  }

  // const updateTasa = async (tasa, newTasa) => {
  //   // Use Parse SDK to update the tasa
  //   const Tasa = Parse.Object.extend('Tasa1')
  //   const query = new Parse.Query(Tasa)
  //   query.get('MkELQrhf6O')
  //     .then((object) => {
  //       object.set('USD', newTasa.USD || tasa.USD)
  //       object.set('MLC', newTasa.MLC || tasa.MLC)
  //       object.set('CUP', newTasa.CUP || tasa.CUP)
  //       object.set('service_percent', newTasa.service_percent || tasa.service_percent)
  //       object.save()
  //         .then((response) => {
  //           return response
  //         })
  //         .catch((error) => {
  //           throw error
  //         })
  //     }
  //     )
  //     .catch((error) => {
  //       throw error
  //     })
  // }

  const updateTasa = (tasa, newTasa) => {
    // Use Parse SDK to update the tasa
    const Tasa = Parse.Object.extend('Tasa')
    const query = new Parse.Query(Tasa)
    return query.get(tasa.objectId)
      .then((object) => {
        object.set('USD', newTasa.USD || tasa.USD)
        object.set('MLC', newTasa.MLC || tasa.MLC)
        object.set('CUP', newTasa.CUP || tasa.CUP)
        object.set('service_percent', newTasa.service_percent || tasa.service_percent)
        return object.save()
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }

  return { tasa, updateTasa }
}

export default useTasa
