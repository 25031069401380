import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SubmitRemesa } from 'helpers/SubmitRemesa'
import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import toast, { Toaster } from 'react-hot-toast'

function ModalComponent ({ openModal, setOpenModal, remesa }) {
  const handleClose = () => setOpenModal(false)
  const [editCant, setEditCant] = useState(false)
  const [cantidad, setCantidad] = useState(remesa.cantidad)
  const [values, setValues] = useState({})

  useEffect(() => {
    setValues(remesa.tipoRemesa === 'mlc'
      ? {
          cantidad,
          nombre: remesa.nombre,
          email: remesa.email,
          telefono: remesa.telefono,
          tarjeta: remesa.envio,
          tipoRemesa: remesa.tipoRemesa,
          confirmation_phone: remesa.confirmation_phone ? remesa.confirmation_phone : '0000000000',
          resend: true
        }
      : {
          cantidad,
          nombre: remesa.nombre,
          email: remesa.email,
          telefono: remesa.telefono,
          direccion: remesa.envio,
          tipoRemesa: remesa.tipoRemesa,
          confirmation_phone: remesa.confirmation_phone ? remesa.confirmation_phone : '0000000000',
          resend: true
        })
  }, [cantidad])
  const resend = async () => {
    const request = await SubmitRemesa(values)
    if (request === true) {
      toast.success('Remesa puesta en cola')
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }

  return (
    <>
      <Toaster />
      <Modal show={openModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remesa: {remesa.objectId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Fecha:</strong> {new Date(remesa.createdAt).toLocaleString()}</p>
          <p><strong>Nombre:</strong> {remesa.nombre}</p>
          <p><strong>Remitente:</strong> {remesa.email}</p>
          {
            editCant
              ? <input
                  type='text'
                  value={cantidad}
                  onChange={(e) => setCantidad(e.target.value)}
                />
              : (
                <p>
                  <strong>Cantidad</strong>: {remesa.cantidad} &nbsp;
                  <Button variant='primary' size='sm' onClick={() => setEditCant(true)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </p>
                )

          }
          <p><strong>Tipo de Remesa:</strong> {remesa.tipoRemesa}</p>
          <p><strong>Envio:</strong> {remesa.envio}</p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={resend}>
            Repetir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalComponent
