// import BootstrapCardDataTable from 'helpers/BootstrapCardDataTable'
import React, { useEffect, useState } from 'react'

const ContactosContext = React.createContext({})

export const ContactosProvider = ({ children }) => {
  const [contactos, setContactos] = useState([])
  const [groups, setGroups] = useState([])

  useEffect(() => {
    getContactos()
    getGroups()
  }, [])

  const getContactos = async () => {
    const response = await fetch(`https://api.sendhub.com/v1/contacts/?username=${process.env.REACT_APP_SENDHUB_USERNAME}&api_key=${process.env.REACT_APP_SENDHUB_APIKEY}&limit=1000`)
    const data = await response.json()
    setContactos(data.objects)
  }

  const getGroups = async () => {
    const response = await fetch(`https://api.sendhub.com/v1/groups/?username=${process.env.REACT_APP_SENDHUB_USERNAME}&api_key=${process.env.REACT_APP_SENDHUB_APIKEY}`)
    const data = await response.json()
    setGroups(data.objects)
  }

  return (<ContactosContext.Provider value={{ contactos, groups, getContactos }}>{children}</ContactosContext.Provider>)
}

export default ContactosContext
