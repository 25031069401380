import React, { useEffect, useState } from 'react'

import uuid from 'react-uuid'
// react-bootstrap components
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalComponent from 'components/Dashboard/ModalComponent'
import {
  Button,
  Card,
  Col,
  Table
} from 'react-bootstrap'
import RemesasSearch from 'components/Search/RemesasSearch'
import useRemesas from 'components/Hooks/useRemesas'

function RemesasList ({ data }) {
  const { title, subtitle, headers, actionButton, extra } = data

  const [datatable, setDatatable] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [remesaModal, setRemesaModal] = useState({})
  const { completadas, currentPage, setCurrentPage, totalPages, loading } = useRemesas()

  useEffect(() => {
    const myData = [].concat(completadas.reverse())
      .sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
    setDatatable(myData)
  }, [completadas])

  const handleDetails = (remesa) => {
    setOpenModal(true)
    setRemesaModal(remesa)
  }

  const buttons = []
  for (let i = 1; i <= totalPages; i++) {
    buttons.push(i)
  }

  console.log({ currentPage, totalPages, completadas })

  return (
    <>
      {
      openModal
        ? <ModalComponent openModal={openModal} setOpenModal={setOpenModal} remesa={remesaModal} />
        : null
    }
      <div className='row' />
      <div className='row'>
        <Col md={{ span: 10, offset: 1 }}>
          <Card className='x'>
            <Card.Header>
              <Card.Title as='h4'> {title} </Card.Title>
              <p className='card-category'>
                {subtitle}
              </p>
              {
                !loading
                  ? <p className='text-center'>Página {currentPage} de {totalPages}</p>
                  : <p className='text-center'>Página ⌛ de {totalPages}</p>
              }
            </Card.Header>
            <Card.Body className='' />
          </Card>
          <Col md='4'>
            {/* Crea un campo de texto para buscar por el campo nombre */}
            <RemesasSearch />
          </Col>
          <Col className='my-3'>
            <div className='d-flex justify-content-between'>
              <button className={`btn btn-primary ${currentPage === 1 ? 'invisible' : ''}`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Anterior</button>
              <div className='d-flex gap-1'>
                {
                buttons.map((button) => (
                  <button className={`btn  ${currentPage === button ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setCurrentPage(button)} key={uuid()}>{button}</button>
                ))
              }
              </div>
              <button className={`btn btn-primary ${currentPage === totalPages ? 'invisible' : ''}`} onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>Siguiente</button>
            </div>
          </Col>
          <Table className='strpied-tabled-with-hover table-hover table-full-width table-responsive px-0 mx-0'>

            <thead>
              <tr>
                <th>
                  No.
                </th>
                {headers.map((th, idex) => (
                  <th key={idex}>{th}</th>
                ))}
                {
                        actionButton !== undefined
                          ? (
                            <th>
                              {
                              extra === 'resend'
                                ? 'Acciones'
                                : 'Acciones'
                            }
                            </th>
                            )
                          : null
                      }

              </tr>
            </thead>
            <tbody>
              {/* slice the data to show only 100 rows using currentPage */}

              {!loading && datatable.map((fila, index) => (
                <tr key={uuid()}>
                  <td>{currentPage === 1 ? index + 1 : (currentPage - 1) * 100 + index + 1}</td>
                  <td>{new Date(fila.createdAt).toLocaleString()}</td>
                  <td>{fila.confirmation_phone}</td>
                  <td>
                    {
                      // Capitalize the first letter of the every word
                    (fila.nombre).toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())
                    }
                  </td>
                  <td>{(fila.telefono)}</td>
                  <td>{(fila.cantidad)}</td>
                  <td>{(fila.pagado)}</td>
                  <td>{(fila.tipoRemesa).toUpperCase()}</td>

                  {
                          actionButton !== undefined
                            ? extra === 'resend'
                              ? (
                                <td>

                                  <Button onClick={() => handleDetails(fila)}>
                                    <FontAwesomeIcon icon={faEye} />
                                  </Button>
                                </td>
                                )
                              : (
                                <td>
                                  <a href={'/products/' + fila.id}><i className='nc-icon nc-ruler-pencil' /></a>
                                  <a href=''><i className='nc-icon nc-tap-01' /></a>
                                </td>)

                            : null
                        }
                </tr>
              ))}
            </tbody>
          </Table>
          {/* </Card.Body>
            </Card> */}
        </Col>
      </div>

    </>
  )
}

export default React.memo(RemesasList)
