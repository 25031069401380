import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useRemesas from 'components/Hooks/useRemesas'
import { Nav } from 'react-bootstrap'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'

function SidebarNav ({ routes }) {
  const { pendientes, loading } = useRemesas()
  const location = useLocation()

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }

  return (
    <Nav>
      {routes.filter(route => route.layout === '/').map((prop, key) => {
        if (!prop.redirect) {
          return (
            <li
              className={
                    prop.upgrade
                      ? 'active active-pro'
                      : activeRoute(prop.layout + prop.path)
                  }
              key={key}
            >
              {
                    prop.path === '/pendientes'
                      ? (
                        <NavLink
                          to={prop.path}
                          className='nav-link'
                        >
                          <FontAwesomeIcon icon={prop.icon} size='xl' />
                          &nbsp;
                          <p style={{ position: 'relative' }}>{prop.name}
                          </p>
                          &nbsp;
                          {
                            pendientes.length > 0
                              ? (
                                  (!loading)
                                    ? (
                                      <span style={{
                                        // position: 'absolute',
                                        backgroundColor: '#fb404b',
                                        textAlign: 'center',
                                        borderRadius: '10px',
                                        minWidth: '18px',
                                        padding: '2px 5px',
                                        height: '18px',
                                        fontSize: '12px',
                                        color: '#fff',
                                        fontWeight: 700,
                                        lineHeight: '18px'

                                      }}
                                      >{pendientes.length}
                                      </span>
                                      )
                                    : (
                                      <FontAwesomeIcon icon={faClock} size='lg' />
                                      )
                                )
                              : null
                          }
                        </NavLink>
                        )

                      : (
                        <NavLink
                          to={prop.path}
                          className='nav-link'
                        >
                          <FontAwesomeIcon icon={prop.icon} size='xl' />
                          &nbsp;
                          <p>{prop.name}</p>
                        </NavLink>
                        )
                  }
            </li>
          )
        }
        return null
      })}
    </Nav>
  )
}

export default SidebarNav
