import useContacts from 'components/Hooks/useContacts'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

const { REACT_APP_SENDHUB_USERNAME, REACT_APP_SENDHUB_APIKEY } = process.env
function EditContact () {
  const { getContactos } = useContacts()
  // obtiene el ID desde la URL
  const { id } = useParams()
  // si no hay contacto, navegar a la pagina de contactos usando useNavigate
  const [contact, setContact] = useState({})
  // crea un estado de carga
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  // TODO: obtener el contacto desde el API
  // Esta es la URL https://api.sendhub.com/v1/contacts/CONTACT_ID/?username=USERNAME&api_key=APIKEY
  // Extrae de las variables de entorno el REACT_APP_SENDHUB_USERNAME y REACT_APP_SENDHUB_APIKEY
  useEffect(() => {
    if (!id) {
      return navigate('/contacts')
    } else {
      fetch(`https://api.sendhub.com/v1/contacts/${id}/?username=${REACT_APP_SENDHUB_USERNAME}&api_key=${REACT_APP_SENDHUB_APIKEY}`)
        .then(response => response.json())
        .then(data => setContact(data))
    }
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const { name, number } = e.target
    const data = {
      id,
      name: name.value,
      number: number.value
    }
    fetch(`https://api.sendhub.com/v1/contacts/${id}/?username=${REACT_APP_SENDHUB_USERNAME}&api_key=${REACT_APP_SENDHUB_APIKEY}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)

    })
      .then(response => response.json())
      .then(data => {
        getContactos()
          .then(() => navigate('/contacts'))
      })
      .catch(error => console.log(error))
  }

  return (
    // Genera una vista del contacto utilizando bootstrap, este es el objeto del contacto
  //   {
  //     "blocked": false,
  //     "date_created": "2012-05-31T17:44:17.406108",
  //     "date_modified": "2012-05-31T17:44:17.406139",
  //     "groups": [],
  //     "id": "22413",
  //     "name": "John Doe",
  //     "number": "+16501234567",
  //     "resource_uri": "/api/v1/contacts/22413/"
  //  }
    <div className='container'>
      <h1>Editar Contacto</h1>
      <div className='row'>
        <Col md='6'>
          {/* Deshabilita el formulario si loading es true */}
          <form onSubmit={handleSubmit}>
            <fieldset disabled={loading}>
              <div className='form-group'>
                <label htmlFor='name'>Name</label>
                <input type='text' defaultValue={contact.name} className='form-control' id='name' placeholder='Enter name' />
              </div>
              <div className='form-group'>
                <label htmlFor='number'>Number</label>
                <input type='text' className='form-control' defaultValue={contact.number} id='number' placeholder='+1234567890' />
              </div>
              <button type='submit' className={`btn mt-3 ${loading ? 'btn-secondary' : 'btn-primary'}`}>
                {
                loading ? 'Guardando...' : 'Guardar'
              }
              </button>
            </fieldset>
          </form>
        </Col>
      </div>
    </div>
  )
}

export default EditContact
