import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputFormikField } from 'components/Forms/InputFormikField'
import useContacts from 'components/Hooks/useContacts'
import useRemesas from 'components/Hooks/useRemesas'
import useTasa from 'components/Hooks/useTasa'
import { Form, Formik } from 'formik'
import CalculateRemesa from 'helpers/CalculateRemesa'
import { SubmitRemesa } from 'helpers/SubmitRemesa'
import { useReducer, useState } from 'react'
import { Alert, Button, Form as Fb, Spinner } from 'react-bootstrap'
import { Toaster, toast } from 'react-hot-toast'
import Select from 'react-select'
import esquema from 'schemes/remesasScheme'
import confetti from 'canvas-confetti'
const initialState = {
  confirmationPhone: '',
  tipoRemesa: '-1'
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CONFIRMATION_PHONE':
      return { ...state, confirmationPhone: action.payload }
    case 'SET_TIPO_REMESA':
      return { ...state, tipoRemesa: action.payload }
    default:
      return state
  }
}

function RemesasLocales () {
  const { tasa } = useTasa()
  const { contactos } = useContacts()
  // const options = contactos.map(c => ({ value: (c.number).substring(2), label: `${c.number} | ${c.name}` }))
  const options = contactos.map((contacto) => ({
    value: contacto.number.substring(2),
    label: `${contacto.number} | ${contacto.name}`
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const { confirmationPhone, tipoRemesa } = state

  const { updateRemesas } = useRemesas()

  return (
    <>
      <Toaster />
      <Formik
        validationSchema={esquema}
        initialValues={{
          telefono: '',
          cantidad: '',
          tarjeta: '',
          nombre: '',
          direccion: ''
        }}
        onSubmit={
          async (values, { resetForm }) => {
            setIsLoading(true)
            console.log('Enviando el Form')
            // console.log(values, confirmationPhone)
            const result = await SubmitRemesa({
              tipoRemesa,
              confirmationPhone,
              values
            })
            setIsLoading(false)
            if (result) {
              resetForm()
              updateRemesas()
              confetti({
                particleCount: 300,
                spread: 150,
                origin: { y: 0.6 }
              })
              return toast.success('Remesa puesta en cola')
            } else {
              resetForm()
              return toast.error('Error al enviar la remesa, intente de nuevo')
            }
          }
        }
      >
        {
          ({ values }) => (
            <Form>
              {/* <InputFormikField name='confirmation_phone' label='Telefono de Confirmacion' id='confirmation_phone' placeholder='Telefono de USA sin el +1' /> */}
              <Select
                className='form-group mt-4 mb-4'
                options={options}
                placeholder='Cliente en USA'
                styles={customStyles}
                onChange={(e) =>
                  dispatch({ type: 'SET_CONFIRMATION_PHONE', payload: e.value })}
              />
              <InputFormikField name='nombre' label='Nombre del Familiar' id='nombre' placeholder='Fulanito Perez' />
              <InputFormikField name='telefono' label='Telefono del Familiar' id='telefono' placeholder='5xxxxxxx' />
              <div className='form-group'>
                <label htmlFor='tipoRemesa'>Tipo de Remesa</label>
                <Fb.Select
                  defaultValue={tipoRemesa}
                  name='tipoRemesa'
                  id='tipoRemesa'
                  className='form-control'
                  onChange={(e) =>
                    dispatch({ type: 'SET_TIPO_REMESA', payload: e.target.value })}
                  required
                >
                  <option value='-1' disabled hidden>
                    Seleccione una opcion
                  </option>
                  <option value='cup'>CUP 💰</option>
                  <option value='usd'>USD 💵</option>
                  <option value='mlc'>MLC 💳</option>
                </Fb.Select>

                {
                  tipoRemesa === 'mlc'
                    ? <InputFormikField
                        label='Tarjeta MLC'
                        name='tarjeta'
                        placeholder='9225 XXXX XXXX XXXX XXXX'
                      />

                    : <InputFormikField
                        label='Direccion Postal de su familiar'
                        name='direccion'
                        placeholder='Calle Tal #123 e/ Tal y Mas Cual. Municipio'
                      />

                }
              </div>
              <InputFormikField name='cantidad' label='Cantidad a Enviar' id='type' placeholder='100' />
              <div className='comp-service w-full flex flex-col gap-4'>

                {values.cantidad !== ''
                  ? tipoRemesa !== '-1' && (
                    <Alert variant='info'>
                      <div className='w-full h-auto  rounded-lg flex items-center p-3'>
                        <div className='data-wrapper w-full h-auto flex flex-col gap-2'>
                          <span className='font-medium w-full text-secondary'>
                            Cobrar: &nbsp;
                            <strong>
                              ${CalculateRemesa(tipoRemesa, values.cantidad, tasa)} USD
                            </strong>
                          </span>
                        </div>
                      </div>
                    </Alert>
                  )
                  : null}
              </div>
              <div className='form-group'>
                <Button variant='info' type='submit' disabled={isLoading} style={{ color: '#000', fontWeight: 'bold' }}>{isLoading
                  ? (
                    <><Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                      />
                      <span className='visually-hidden'>Enviando...</span>
                    </>
                    )
                  : <><FontAwesomeIcon icon={faPaperPlane} /> Enviar</>}
                </Button>
              </div>
            </Form>
          )
        }
      </Formik>
    </>
  )
}

const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? '0 0 0 1px blue' : null,
    borderColor: state.isFocused ? 'blue' : null,
    '&:hover': {
      borderColor: state.isFocused ? 'blue' : null
    }
  })
}

export default RemesasLocales
