import useRemesas from 'components/Hooks/useRemesas'
import RemesasList from 'components/Tables/RemesasList'
import { useEffect } from 'react'

function RemesasHistory () {
  const { completadas, getRemesasCompletadas } = useRemesas()

  useEffect(() => {
    getRemesasCompletadas()
  }, [])

  const data = {
    title: 'Listado de Remesas',
    subtitle: 'Remesas Pagadas',
    headers: ['fecha', 'Telefono Remitente', 'nombre', 'telefono', 'cantidad', 'pagado', 'tipoRemesa'],
    dataSet: completadas.reverse(),
    inObjects: 'name',
    actionButton: true,
    extra: 'resend'

  }

  return (
    <div>

      <>
        <RemesasList data={data} />
      </>

    </div>
  )
}

export default RemesasHistory
